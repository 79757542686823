import { AxiosError } from 'axios';
import { SiteStatus, SiteType } from 'components/SiteCard/types';
import { IPagination } from 'store/common/types';
import { RoleType, RoleDescription } from 'store/orderOrganization/types';
import { IDashboardOrderBusinessSource } from 'store/dashboard/types';
import { ITransactionParty } from '../files/types';
import { IUser } from '../users/types';
import { DepositStatus, IRelatedDeposit } from 'store/deposits/types';
import {
  FundType,
  EmdMilestoneTransactionTypes,
} from 'components/Deposits/constants';
import { BorrowerTransactionTypes } from 'store/search/types';
import { IBulkUpdateStatus } from 'components/MultiSiteBulkUpdates/Shared';
import { DocumentStatuses } from 'components/OrderDocuments/DocumentStatus/types';
import { FA_NCS_ClarityFirst_DocumentInstance_Models_WatermarkType } from 'services/__generated__/models/FA_NCS_ClarityFirst_DocumentInstance_Models_WatermarkType';
import { Workspace_Common_Models_Region } from 'services/__generated__/models/Workspace_Common_Models_Region';

export enum OrderRequestTypes {
  NONE = 'NONE',
  ORDER = 'ORDER',
  ORDER_PRODUCTS = 'ORDER_PRODUCTS',
  ORDER_BUSINESS_PARTIES = 'ORDER_BUSINESS_PARTIES',
  ORDER_PRODUCTION_OFFICES = 'ORDER_PRODUCTION_OFFICES',
  ORDER_PARTIES = 'ORDER_PARTIES',
  ORDER_OTHER = 'ORDER_OTHER',
}

export type TransactionType =
  | 'Accommodation'
  | 'Bulk Sale'
  | 'Construction Disbursement'
  | 'Construction Finance'
  | 'Construction Loan'
  | 'Equity Loan'
  | 'Foreclosure'
  | 'Limited Escrow'
  | 'Mortgage Modification'
  | 'Mtg Mod w/Endorsement'
  | 'Mtg Mod w/Increased Liability'
  | 'REO Sale With Mortgage'
  | 'REO Sale w/Mortgage'
  | 'REO Sale/Cash'
  | 'REO'
  | 'Refinance'
  | 'Sale With Construction Loan'
  | 'Sale With Mortgage'
  | 'Sale w/Construction Loan'
  | 'Sale w/Mortgage'
  | 'Sale/Cash'
  | 'Sale/Exchange'
  | 'Search Package'
  | 'Second Mortgage'
  | 'Settlement Statement Only'
  | 'Short Sale w/Mortgage'
  | 'Short Sale'
  | 'Short Sale/Cash';

export const EMD_MILESTONE_TRANSACTION_TYPES: EmdMilestoneTransactionTypes[] = [
  EmdMilestoneTransactionTypes.ConstructionFinance,
  EmdMilestoneTransactionTypes.ConstructionLoan,
  EmdMilestoneTransactionTypes.LimitedEscrow,
  EmdMilestoneTransactionTypes.REOSalewMortgage,
  EmdMilestoneTransactionTypes.REOSaleCash,
  EmdMilestoneTransactionTypes.SalewConstructionLoan,
  EmdMilestoneTransactionTypes.SalewMortgage,
  EmdMilestoneTransactionTypes.SaleCash,
  EmdMilestoneTransactionTypes.SaleExchange,
  EmdMilestoneTransactionTypes.ShortSalewMortgage,
];

export const SETTLEMENT_BORROWER_TRANSACTION_TYPES: string[] = [
  BorrowerTransactionTypes.Refinance,
  BorrowerTransactionTypes.ConstructionDisbursement,
  BorrowerTransactionTypes.ConstructionFinance,
  BorrowerTransactionTypes.EquityLoan,
  BorrowerTransactionTypes.MtgModwEndorsement,
  BorrowerTransactionTypes.MtgModwIncreasedLiability,
];

export interface IPartialOrder {
  fileID: string;
  fileNumber: string;
  name: string;
}

export interface IOrder extends IPartialOrder {
  _meta: IOrderMeta;
  addresses: IAddress[];
  businessParties?: IBusinessParty[];
  businessPartyAndEmployees: IOrderBusinessParty[];
  businessSource: IDashboardOrderBusinessSource;
  businessSourceContact: IDashboardOrderBusinessSource;
  charges?: IOrderCharge[];
  closeDate: null | string;
  closingGroup?: IClosingGroup;
  deposits?: IOrderDeposits;
  milestoneDeposits?: IMilestoneDeposits;
  disbursementInfo?: IOrderDisbursement;
  disbursements?: IOrderDisbursement[];
  escrowProductionOffices: IProductionOffice[];
  estimatedSettlementDate: null | string;
  fileType: SiteType;
  isCloseDateEstimated: boolean;
  isCustomerDocumentExist: boolean;
  loanAmounts: ILoanAmount[];
  payoffLoans?: ILoanAmount[];
  holdFunds: IOrderHoldFund[];
  offices: Array<IProductionOffice | IOwningOffice>;
  openDate: null | string;
  orderParties: IOrderBusinessParty[];
  originalFileId: string;
  otherParties: IOrderBusinessParty[];
  overdrafts?: any;
  ownerLiabilities: IOwnerLiability[];
  ownerLiability?: null | number;
  ownerLiabilityCount: null | number;
  parties?: IOrderBusinessParty[];
  productionOffices: IProductionOffice[];
  products?: IOrderProduct[];
  brokers?: IOrderBroker[];
  projectFileId: number;
  projectFileNumber: string;
  region?: Workspace_Common_Models_Region;
  services: IService[];
  settlementDate?: null | string;
  status: SiteStatus;
  titleProductionOffices: IProductionOffice[];
  totalSalesPrice: null | number;
  transactionType: TransactionType;
  salesPrices?: ISalePrice[];
  isFileAccessExpired: boolean;
  latestIngestedDate?: null | string;
  assumptionLoans: ILoanAmount[];
}

export interface IOrderPropertyFullAddress {
  addressId: number;
  addressType: string | null;
  apns: string[];
  city: string | null;
  country: string | null;
  county: string | null;
  fastId: number | null;
  fastPropertyId: number | null;
  fileId: number;
  geocodingStatus: number;
  latitude: number | null;
  longitude: number | null;
  propertyType: string | null;
  propertyTypeId: number | null;
  seqNum: number | null;
  state: string | null;
  street: string | null;
  streetLine1: string | null;
  streetLine2: string | null;
  streetLine3: string | null;
  streetLine4: string | null;
  zip: string | null;
}

export enum GeocodingStatus {
  Mapped = 'Mapped',
  NotMapped = 'NotMapped',
  Unmappable = 'Unmappable',
}

export interface IOrderSiteGeodata {
  address: {
    city: string;
    country: string;
    county: string;
    state: string;
    street: string;
    zip: string;
  };
  fileID: number;
  fileNumber: string;
  geocodingStatus: 'Mapped' | 'NotMapped' | 'Unmappable';
  latitude: number | null;
  longitude: number | null;
  name: string;
}

export interface IOrderDeposits {
  data?: IOrderDeposit[];
  error?: AxiosError;
}

export interface IMilestoneDeposits {
  milestoneData?: IMilestoneDeposit[];
  error?: AxiosError;
}

export interface INewOrderEmployee {
  id: number;
  businessPartyRoleId: number;
}

export interface IDepositAllocation {
  assignedAmount: number; // decimal
  assignedPercent: number | null; // decimal
  depositId: number;
  id: number;
  siteFileId: number;
}

export interface IDepositDocumentDetails {
  documentId: number;
  displayName: string;
  documentSize: number;
  documentName?: string;
  fileExtension: string;
  docContent: Blob | File;
}

export interface IOrderDeposit {
  adjustments: number;
  amount: number; // decimal
  unallocatedAmount?: number; // decimal
  depositAllocations?: IDepositAllocation[];
  creditTo: string;
  activities: IOrderDeposit[];
  depositType: string;
  depositDocs: JSX.Element;
  description: string;
  documentId: number;
  documentNumber: string;
  id: number;
  incomingFundId?: number;
  issueDate: Date;
  paymentType: FundType;
  sendingBank: string;
  externalDocuments: IDepositDocumentDetails[];
  sendingBankContact: string;
  source: string;
  sourceType: string;
  status: DepositStatus;
  treatAsCreditToOther: boolean;
  receiptedBy: string;
  confirmationNumber?: string;
  federalRoutingNumber?: string;
  confirmationDate?: Date;
  checkNumber?: string;
  checkABANumber?: string;
  checkAccountNumber?: string;
  checkPayorBankName?: string;
  splitNumber?: number;
  isSplit?: boolean;
  isManual?: boolean;
  isReconciled?: boolean;
  relatedSplitDeposits?: IRelatedDeposit[];
  isConsideredEarnestMoney?: boolean;
  isExcessCashDeposits?: boolean;
  isCheckImageAvaliable: boolean;
}

export const ORDER_DISBURSEMENT_EXCLUDED_PAYMENT_METHODS = ['IBA'];

export enum OrderDisbursementType {
  AdjustmentsMiscellaneous = 696,
  AdjustmentsOffSet = 350,
  AssumptionLoan1 = 353,
  AssumptionLoan2 = 354,
  AttorneyBuyer = 322,
  AttorneySeller = 325,
  BrokerBuyer = 323,
  BrokerSeller = 326,
  Buyer = 113,
  HOAManagementCompany = 357,
  HoldFundsDisbursement = 693,
  HoldFundsEntry = 355,
  HomeownerAssociation = 93,
  HomeWarranty = 96,
  InspectionRepairOther = 427,
  InspectionRepairSeptic = 705,
  InsuranceEarthquake = 362,
  InsuranceFire = 359,
  InsuranceFlood = 360,
  InsuranceOther = 363,
  InsuranceWind = 361,
  LeaseRent = 364,
  MiscellaneousDisbursements = 365,
  MiscellaneousProration = 430,
  MortgageBroker = 670,
  NewLoan1Lender = 366,
  NewLoan1MortgageBroker = 367,
  NewLoan2Lender = 368,
  NewLoan2MortgageBroker = 369,
  OtherRealEstateBroker = 675,
  OutsideEscrow = 646,
  OutsideTitleCompany = 317,
  PayOffLoan1 = 370,
  PayOffLoan2 = 371,
  PayOffLoan3 = 372,
  PestInspectionRepair = 425,
  PropertyTax = 373,
  ProrationTax = 697,
  RealEstateBroker = 375,
  RealEstateBrokerDisbursement = 692,
  RentProration = 428,
  SalePrice = 737,
  Seller = 114,
  Survey = 376,
  Utility = 378,
}

export enum OrderDisbursementTypeFeeEntry {
  TitleProductFeeCategory = 58,
  EscrowBaseFeeCategory = 59,
  TitleOtherFeeCategory = 61,
  EscrowOtherFeeCategory = 62,
  RecordingFeeCategory = 63,
  DocumentaryTransferTaxCategory = 64,
  OtherRecordingFeeCategory = 683,
}

export const ORDER_DISBURSEMENT_CHECK_POSITIVE_ID_TYPES = [
  OrderDisbursementType.HomeownerAssociation,
  OrderDisbursementType.HOAManagementCompany,
];

export const ORDER_DISBURSEMENT_CHARGE_BASED_TYPES = [
  OrderDisbursementType.AttorneyBuyer,
  OrderDisbursementType.AttorneySeller,
  OrderDisbursementType.MiscellaneousDisbursements,
  OrderDisbursementType.OutsideEscrow,
  OrderDisbursementType.PropertyTax,
  OrderDisbursementType.Utility,
  OrderDisbursementType.BrokerBuyer,
  OrderDisbursementType.BrokerSeller,
  OrderDisbursementType.HOAManagementCompany,
  OrderDisbursementType.HomeownerAssociation,
  OrderDisbursementType.OtherRealEstateBroker,
  OrderDisbursementType.OutsideTitleCompany,
];

export interface IWireInstructionDocument {
  documentId: number;
  name: string;
  size: number;
}

export interface IWireInstructions {
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  bankRoutingNumber: string;
  beneficiaryBankName?: string;
  beneficiaryBankCode?: string;
  document: IWireInstructionDocument;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  wireInstructionFileId?: number;
}

export interface IOrderDisbursementCharge {
  buyerPaidAtClosing: number;
  buyerPaidOutsideClosing: number;
  chargeTotal: number;
  description: string;
  id?: number;
  sellerPaidAtClosing: number;
  sellerPaidOutsideClosing: number;
}

export interface IOrderCharge {
  allocatedTotal: number;
  businessOrganizationId?: number;
  businessPartyName1?: string;
  businessPartyName2?: string;
  businessPartyRoleDescription: string;
  businessPartyRoleId: number;
  disbursableTotal: number;
  allocatableTotal: number;
  id: number;
  isIssued: boolean;
  isOverpaid: boolean;
  isPartiallySupported: boolean;
  issuedTotal: number;
  isUnderpaid: boolean;
  organization: string;
  typeDescription: string;
  typeId: number;
}

export interface IOrderHoldFundDisbursement {
  id: number;
  organization: string;
  disbursableTotal: number;
  issuedTotal: number;
  isIssued: boolean;
}
export interface IOrderHoldFund {
  actualReleaseDate: string;
  amountReleased: number;
  amountRemaining: number;
  allocatedTotal: number;
  disbursements: IOrderHoldFundDisbursement[];
  disbursementsIssued: string;
  estimatedReleaseDate: string;
  hasAllocations: boolean;
  heldAmount: number;
  heldFrom: string;
  id: number;
  isHoldFundsAgreement: boolean;
  reason: string;
  startDate: string;
}

export interface IOrderProrationsAdjustmentsCharge {
  description: string;
  buyerCharge: number;
  buyerCredit: number;
  sellerCharge: number;
  sellerCredit: number;
  allocatedTotal?: number;
  id?: number;
  templateId?: number;
  hasAllocations?: boolean;
}

export interface IOrderDisbursementCheckOnlyDetails {
  description: string;
  amount: number;
}

export interface IWireRejectedBy {
  name: string;
  rejectedAt: string;
}
export interface IWireApprover {
  name: string;
  approvedAt: string;
}

interface IWireApproval {
  approvers: IWireApprover[];
  required: number;
}

export interface IIssuedBy {
  employeeId: number;
  firstName: string;
  lastName: string;
}

export interface IOrderDisbursement {
  id: number;
  fileProcessId: number;
  superDisbursementId: number | null;
  payeeName: string;
  payeeId: number;
  typeId: number;
  typeDescription?: string;
  subTypeId?: string | null;
  subTypeDescription?: string | null;
  paymentMethod: string | null;
  status: string;
  issuedDate?: Date | null;
  issueDate: Date | null;
  isWireVerified: boolean;
  issuedBy?: IIssuedBy;
  buyerTotalAmount?: number;
  sellerTotalAmount?: number;
  totalAmount: number;
  amount?: number;
  documentNumber?: string;
  referenceNote?: string;
  description?: string;
  chargeDetails?: string;
  memo?: string;
  charges?: IOrderDisbursementCharge[];
  checkOnlyDetails?: IOrderDisbursementCheckOnlyDetails;
  wireInstructions?: IWireInstructions;
  wireApproval?: IWireApproval;
  sdnStatus: SDNStatus;
  superDisbursement?: IOrderDisbursement;
  confirmationNumber?: string | null;
  orderId?: string;
  splitDetails?: IOrderDisbursementChildSplit;
  allSplits?: IOrderDisbursementChildSplit[];
  adjustmentHistory?: IOrderDisbursementHistory[];
  bankAccountId?: number;
}

export interface IOrderDisbursementChildSplit {
  id?: number;
  status: string;
  amount: number;
  percentage: number;
  payeeName: string;
  payeeAddress: string;
  paymentMethod: string;
  wireInstructions?: IWireInstructions;
}

export interface IOrderDisbursementHistory {
  disbursementId: number;
  statusId: number;
  statusDescription: string | null;
  splitIndicator: string;
  paymentMethod: string;
  documentNumber: string;
  disbursementDate: string | null;
  disbursementAmount: number;
  payeeName: string;
  description: string;
  adjustmentDate?: string;
  adjustmentType?: number;
  reasonType?: string;
  reason?: string;
}

export interface IDisbursementBulkIssue {
  disbursementId: number;
  disbursementTypeId: number;
  disbursementType: string;
  statusId: number;
  status: string;
  disbursementDetail: {
    documentNumber: string;
    statusId: number;
    status: string;
    issuedate: string;
    disbursedAmount: number;
    issuedCheck: {
      deliveryEventLogId: number;
      checkId: string;
      deliveryStatusId: number;
      deliveryStatus: string;
    };
    payee: {
      id: number;
      payeeName: string;
      address: string;
      fileProcessId: number;
    };
  };
}

export interface IDisbursementBulkIssueResponse {
  issuedDisbursements: IDisbursementBulkIssue[];
}

export type SDNStatus = 'Cleared' | 'In Process' | 'Hit Found' | 'No Hit';

export enum SDNStatuses {
  Cleared = 'Cleared',
  InProcess = 'In Process',
  HitFound = 'Hit Found',
  NoHit = 'No Hit',
}

export type CheckDeliveryStatus = 'Processing' | 'Success';

export interface IOrderOverdraft {
  id: number;
  sequenceNumber: number;
  approvedAmount: number;
  balanceAmount: number;
  issuedAmount: number;
  recoveredAmount: number;
  lockStatus: string;
  status: string;
}

export interface IOrderOverdraftHistory {
  amount: number;
  date: string;
  method: string;
  payeeName: string;
  status: string;
  type: string;
}

export interface IOverdraftRecoverableType {
  id: number;
  description: string;
}

export interface IOverdraftCause {
  id: number;
  description: string;
}

export interface IOverdraftRecoverableParty {
  id: number;
  name: string;
  role: string;
  address: string;
}

export interface IOrderOverdraftDetail extends IOrderOverdraft {
  tableClosingFlag: boolean;
  otherReasonForLossExplanation: string;
  unrecoverableReason: string;
  recoverableAmount: number;
  lossRecoverableFlag: boolean;
  recoverableParties: Array<{
    businessPartyName: string;
    businessPartyId: number;
  }>;
  type: string;
  otherRecoverableTypeExplanation: string;
  overdraftCause: { id: number; description: string };
  recoverableType: { id: number; description: string };
  otherCauseExplanation: string;
  comments: string;
  recoveredDeposits: IOrderOverdraftHistory[];
  associatedDisbursements: IOrderOverdraftHistory[];
  associatedDeposits: IOrderOverdraftHistory[];
}

export interface IOverdraftAllocation {
  sequenceNumber: number;
  allocatedAmount: number;
  approvedAmount: number;
  balanceAmount: number;
  canAllocate: boolean;
  issuedAmount: number;
  overdraftId: number;
  recoveredAmount: number;
}

export interface IOrderMeta {
  permissions: string[];
  projectFileData?: {
    cityCount?: number;
    closedSiteFileCount?: number;
    countyCount?: number;
    isCloseDateEstimated?: boolean;
    latestClosingDate?: string;
    orderTypes?: string[];
    stateCount?: number;
    statuses?: {
      closed?: number;
      open?: number;
    };
    totalLiability?: number;
  };
}

export interface IBusinessParty {
  organizationId: number;
  roleDescription: string;
}

export interface IOwnerLiability {
  id: number;
  fileId: number;
  sequenceNumber: number;
  amount: null | number;
}

export interface IAddress {
  addressId: number;
  street?: string;
  city: string;
  county: string;
  zip: string;
  state: string;
  country: string;
  seqNum?: number;
  fastId?: number;
  propertyType: string;
  address?: string;
  apns?: string[];
}

export interface IService {
  fileServiceId: number;
  description: string;
  fileId?: number;
}

export interface ILoanAmount {
  id: number;
  amount: number;
  sequenceNumber: number;
  liabilityAmount: number;
  lenderFastId?: number;
  projectLoanFastId?: number | null;
  fastId?: number;
  loanKind?: number;
}

export interface ISalePrice {
  amount: number | null;
  fileId: number;
  sequenceNumber: number;
}

export interface IOrderState {
  affiliatesData?: IAffiliate[];
  loading: boolean;
  requestType: OrderRequestTypes;
  error: null | AxiosError;
  order: IOrder | null;
  status: string | null;
  officeViewSearchValue: string | null;
  orderPropertyFullAddresses: IOrderPropertyFullAddress[];
  documents: IDocument[];
  documentAssociations: Record<string, string[]>;
  overdrafts: IOrderOverdraft[];
  overdraftRecoverableTypes: IOverdraftRecoverableType[];
  overdraftCauses: IOverdraftCause[];
  overdraftRecoverableParties: IOverdraftRecoverableParty[];
  documentPermissionError: null | AxiosError;
  documentPermissionCandidateParties: IOrderBusinessParty[];
  documentPartiesWithPermissions: IOrderBusinessParty[];
  documentOrganizations: IOrderBusinessPartiesHash;
  documentOrganizationContacts: IOrderBusinessPartiesHash;
  eagleProPackagesError: AxiosError | null;
  eagleProPackagesLatest: IEagleProPackage | null;
  salesPrices: ISalePrice[];
  multisiteProject: IMultisiteProjectState;
  multisiteProjectGeodata: {
    geodata: IOrderSiteGeodata[];
    unmappableCount: number;
  };
  multisiteSiteBreadcrumbLinkInfo: IMultisiteSiteBreadcrumbLinkInfo;
  orderOrganizations: IOrderBusinessPartiesHash;
  orderOrganizationContacts: IOrderPartyContactsHash;
  ui: IOrderUiState;
  isDMSEnabled: boolean;
}

export interface IMultisiteProjectState {
  locations?: {
    [state: string]: number;
  };
  pagination: IPagination;
  sites: any;
}

export interface IRemoveSitesFromMultisiteOpts {
  projectFileId: number;
  refetch: () => void;
  sitesToRemove: any[];
  onSuccess: (successfulIndexes: number[]) => void;
  onError: (erroneousIndexes: number[]) => void;
}
interface IMultisiteSiteBreadcrumbLinkInfo {
  [siteId: number]: ProjectLinkInfo;
}

export enum ProjectLinkTypes {
  SitesList = 'Sites List',
  SitesMap = 'Sites Map',
}

export type ProjectLinkInfo =
  | {
      type: ProjectLinkTypes.SitesList;
      query: ISitesQuery;
    }
  | {
      type: ProjectLinkTypes.SitesMap;
      lat: number;
      lng: number;
      zoom: number;
      displaySitesList?: boolean;
      selectedOrder?: number;
    };

export type DocumentId = number | string;

export interface ISingleDocumentDownload {
  documentIds: DocumentId[];
  totalFileSize: number;
}

export interface IDocumentMetadata {
  type: string;
  subType: string;
  displayName?: string;
  additionalInfo?: string;
  notes?: string;
  secured?: boolean;
  depositId?: number;
  sdnHitId?: number;
  documentId?: number;
  uniqueId?: string;
}

export interface IStagedDocumentUpload
  extends Pick<IOrder, 'fileID' | 'fileNumber'> {
  fastId?: number;
  isSecured?: boolean;
  file: File;
  metadata?: Partial<IDocumentMetadata>;
}

export interface IDocumentUpload extends IStagedDocumentUpload {
  metadata: IDocumentMetadata;
  customerDocumentId?: string;
}

export interface ICompletedDocumentUpload extends IDocumentUpload {
  error?: Error;
}

export interface IInProgressDocumentUpload extends IDocumentUpload {
  progress: number; // 0 - 100
}

export function isInProgressUpload(
  upload: IDocumentUpload | IInProgressDocumentUpload
): upload is IInProgressDocumentUpload {
  const progress = (upload as IInProgressDocumentUpload).progress;
  return progress !== undefined && progress >= 0 && progress <= 100;
}

export interface IDocumentType {
  description: string;
  subTypes?: string[];
}

export interface IDocumentTypeResponse {
  documentTypes: IDocumentType[];
}

export interface IOrderUiState {
  documentPermissions: {
    contactInputIds: string[];
  };
  multiDocumentsDownload: {
    orderId: number | null;
    documentIds: number[];
    documentArchiveFilesize: number;
    downloadPromptVisible: boolean;
  };
  removingDocumentId: number | string | null;
  singleDocumentDownload: ISingleDocumentDownload;

  multiDocumentsUpload: {
    stagedDocuments: IStagedDocumentUpload[];
    inProgressUploads: IInProgressDocumentUpload[];
    finishedUploads: ICompletedDocumentUpload[];
  };

  multiSitesRemoval: {
    projectFileId: number | null;
    siteFileIds: number[];
  };

  depositReceiptsProgress: {
    receiptsMetaData: IDepositReceiptProgressMetaData[];
  };

  issueCheckDocumentProgress: {
    items: IIssueCheckDocumentProgressMetaData[];
  };

  settlementDocumentProgress: {
    items: ISettlementDocumentItemProgress[];
  };

  multisiteSettlementDocuments: {
    error: boolean;
    items: IMultisiteDocumentItem[];
  };

  bulkUpdatesStatusProgress: {
    siteOrders: IBulkUpdateStatus[];
    isLoanAmountAllocation: boolean;
    fetchOrderDetailsOnFinish: boolean;
  };

  deleteOrderLoanProgress: {
    items: IBulkUpdateStatus[];
    fetchOrderDetailsOnFinish: boolean;
  };
}

export interface IDepositReceiptProgressMetaData {
  representingType: string;
  amount: number;
  orderId: number;
  depositId: number;
  documentId: number;
}

export type IIssueCheckDeliveryStatusProgress = Record<
  string,
  { description: CheckDeliveryStatus }
>;

export type IIssueCheckDialogData = Array<{
  disbursementId: number;
  payeeName: string;
}>;

export interface IIssueCheckOverdraftData {
  checks: IIssueCheckDialogData;
  hasWires?: boolean;
}

export interface IDocumentProgressMetaData {
  format?: 'pdf' | 'docx';
  download?: boolean;
  print?: boolean;
  saveToCF?: boolean;
  fetching?: boolean;
  completed?: boolean;
  errored?: boolean; // error while fetching check image byte content?
  statusAPI?: string; // custom item API for status check
  errorText?: string; // custom item error text
}

export interface IIssueCheckDocumentProgressMetaData
  extends IDocumentProgressMetaData {
  orderId: number;
  checks: IIssueCheckDialogData;
  deliveryStatus?: IIssueCheckDeliveryStatusProgress; // has the check image been generated
}

export interface ISettlementDocumentProgressMetaData {
  previewId: number;
  documentId?: number;
  status: string;
  bytes: unknown;
}

export interface ISettlementDocumentItemProgress
  extends IDocumentProgressMetaData {
  orderId: string;
  previewId?: number;
  name: string;
  location: string;
  status?: string;
  bytes: unknown;
  documentId?: number;
}

export interface IMultisiteExportDocument {
  bytes: string | number;
  fileName: string;
}

export interface IMultisiteExportResponse {
  data: IMultisiteExportDocument;
}

export interface IExportMultipleResponse {
  data: IMultisiteExportDocument;
}

export interface IMultisiteDocumentItem extends IDocumentProgressMetaData {
  id: string;
  orderId: string;
  name: string;
  location: string;
  bytes: unknown;
  status?: 'Processing' | 'Success';
}

export interface IDisbursementDeleteMetaData {
  orderId: number;
  fileProcessId: number;
}

export enum OrderTypeKeys {
  REQUEST = 'order/REQUEST',
  SUCCESS = 'order/SUCCESS',
  ERROR = 'order/ERROR',
  CLEAR = 'order/CLEAR',
  CLEAR_ERROR = 'order/CLEAR_ERROR',
  RECEIVE_ORDER_RESULTS = 'order/RECEIVE_ORDER_RESULTS',
  RECEIVE_ORDER_PRODUCTS_RESULTS = 'order/RECEIVE_ORDER_PRODUCTS_RESULTS',
  RECEIVE_ORDER_BUSINESS_PARTIES_RESULTS = 'order/RECEIVE_ORDER_BUSINESS_PARTIES_RESULTS',
  RECEIVE_ORDER_PRODUCTION_OFFICES_RESULTS = 'order/RECEIVE_ORDER_PRODUCTION_OFFICES_RESULTS',
  RECEIVE_ORDER_OWNING_OFFICES_RESULTS = 'order/RECEIVE_ORDER_OWNING_OFFICES_RESULTS',
  RECEIVE_ORDER_TITLE_PRODUCTION_OFFICES_RESULTS = 'order/RECEIVE_ORDER_TITLE_PRODUCTION_OFFICES_RESULTS',
  RECEIVE_ORDER_ESCROW_PRODUCTION_OFFICES_RESULTS = 'order/RECEIVE_ORDER_ESCROW_PRODUCTION_OFFICES_RESULTS',
  RECEIVE_ORDER_OTHER_PARTIES_RESULTS = 'order/RECEIVE_ORDER_OTHER_PARTIES_RESULTS',
  RECEIVE_ORDER_PARTIES_RESULTS = 'order/RECEIVE_ORDER_PARTIES_RESULTS',
  REMOVE_ORDER_PARTY_CONTACT = 'order/REMOVE_ORDER_PARTY_CONTACT',
  REMOVE_ORDER_PARTY_EMPLOYEE = 'order/REMOVE_ORDER_PARTY_EMPLOYEE',
  REVOKE_ORDER_ACCESS = 'order/REVOKE_ORDER_ACCESS',
  GRANT_ORDER_ACCESS = 'order/GRANT_ORDER_ACCESS',

  SET_AFFILIATES_DATA = 'order/SET_AFFILIATES_DATA',
  SET_FILTER_CARD_STATUS = 'order/SET_FILTER_CARD_STATUS',
  GET_FILTER_CARD_STATUS = 'order/GET_FILTER_CARD_STATUS',
  SET_OFFICE_VIEW_SEARCH_VALUE = 'order/SET_OFFICE_VIEW_SEARCH_VALUE',

  // Edit order
  UPDATE_ORDER_NAME_SUCCESS = 'orders/UPDATE_ORDER_NAME_SUCCESS',
  UPDATE_ORDER_CLOSE_DATE_SUCCESS = 'orders/UPDATE_ORDER_CLOSE_DATE_SUCCESS',
  UPDATE_ORDER_LOAN_AND_LIABILITY = 'orders/UPDATE_ORDER_LOAN_AND_LIABILITY',
  UPDATE_ORDER_PRODUCTS_SUCCESS = 'orders/UPDATE_ORDER_PRODUCTS_SUCCESS',
  UPDATE_ORDER_SERVICES_COMPLETE = 'orders/UPDATE_ORDER_SERVICES_COMPLETE',
  SET_ORDER_PROPERTIES_ADDRESSES = 'orders/SET_ORDER_PROPERTIES_ADDRESSES',
  SET_ORDER_ADDRESSES = 'orders/SET_ORDER_ADDRESSES',

  // Order deposits
  REQUEST_ORDER_DEPOSITS_SUCCESS = 'order/REQUEST_DEPOSITS_SUCCESS',
  REQUEST_ORDER_DEPOSITS_ERROR = 'order/REQUEST_DEPOSITS_ERROR',
  SET_ORDER_DEPOSIT_RECEIPT_PROGRESS = 'order/SET_ORDER_DEPOSIT_RECEIPT_PROGRESS',
  CLEAR_ORDER_DEPOSIT_RECEIPT_PROGRESS = 'order/CLEAR_ORDER_DEPOSIT_RECEIPT_PROGRESS',
  REQUEST_ORDER_MILESTONE_DEPOSITS_SUCCESS = 'order/REQUEST_MILESTONE_DEPOSITS_SUCCESS',
  REQUEST_ORDER_MILESTONE_DEPOSITS_ERROR = 'order/REQUEST_MILESTONE_DEPOSITS_ERROR',

  // Order charges
  REQUEST_ORDER_CHARGES_SUCCESS = 'order/REQUEST_CHARGES_SUCCESS',
  REQUEST_ORDER_HOLD_FUNDS_SUCCESS = 'order/REQUEST_ORDER_HOLD_FUNDS_SUCCESS',

  // Order disbursements
  REQUEST_ORDER_DISBURSEMENTS_SUCCESS = 'order/REQUEST_DISBURSEMENTS_SUCCESS',
  DELETE_ORDER_DISBURSEMENT_SUCCESS = 'order/DELETE_DISBURSEMENT_SUCCESS',
  SET_ISSUE_CHECK_DOCUMENT_PROGRESS = 'order/SET_ISSUE_CHECK_DOCUMENT_PROGRESS',
  CLEAR_ISSUE_CHECK_DOCUMENT_PROGRESS = 'order/CLEAR_ISSUE_CHECK_DOCUMENT_PROGRESS',
  REPLACE_ORDER_DISBURSEMENT_ID = 'order/REPLACE_ORDER_DISBURSEMENT_ID',

  // Settlement Statements
  SET_SETTLEMENT_DOCUMENT_PROGRESS = 'order/SET_SETTLEMENT_DOCUMENT_PROGRESS',
  CLEAR_SETTLEMENT_DOCUMENT_PROGRESS = 'order/CLEAR_SETTLEMENT_DOCUMENT_PROGRESS',

  // Order disbursement info
  REQUEST_ORDER_DISBURSEMENT_INFO_SUCCESS = 'order/REQUEST_DISBURSEMENT_INFO_SUCCESS',

  // Order overdrafts
  REQUEST_ORDER_OVERDRAFTS_SUCCESS = 'order/REQUEST_ORDER_OVERDRAFTS_SUCCESS',
  REQUEST_OVERDRAFTS_RECOVERABLE_TYPES_SUCCESS = 'order/REQUEST_OVERDRAFTS_RECOVERABLE_TYPES_SUCCESS',
  REQUEST_OVERDRAFTS_CAUSES_SUCCESS = 'order/REQUEST_OVERDRAFTS_CAUSES_SUCCESS',
  REQUEST_OVERDRAFTS_RECOVERABLE_PARTIES_SUCCESS = 'order/REQUEST_OVERDRAFTS_RECOVERABLE_PARTIES_SUCCESS',

  // Add contact to order type keys
  SET_ORDER_ORGANIZATIONS = 'order/SET_ORDER_ORGANIZATIONS',
  SET_ORDER_ORGANIZATION_CONTACTS = 'order/SET_ORDER_ORGANIZATION_CONTACTS',

  // Documents type keys
  DOCUMENT_PERMISSIONS_ERROR = 'order/DOCUMENT_PERMISSIONS_ERROR',
  RECEIVE_ORDER_DOCUMENTS = 'order/RECEIVE_ORDER_DOCUMENTS',
  REMOVE_CACHED_ORDER_DOCUMENT = 'order/REMOVE_CACHED_ORDER_DOCUMENT',
  SET_DOC_ORGANIZATIONS = 'order/SET_DOC_ORGANIZATIONS',
  REQUEST_DOC_ORGANIZATION_CONTACTS = 'order/REQUEST_DOC_ORGANIZATION_CONTACTS',
  DOCUMENT_ERROR = 'orders/DOCUMENT_ERROR',
  SET_DOC_ORGANIZATION_CONTACTS = 'order/SET_DOC_ORGANIZATION_CONTACTS',
  ADD_DOC_PARTY_TO_ORDER = 'orders/ADD_DOC_PARTY_TO_ORDER',
  ADD_DOC_PARTY_TO_PARTIES_WITH_PERMISSIONS = 'orders/ADD_DOC_PARTY_TO_PARTIES_WITH_PERMISSIONS',
  RECEIVE_ORDER_DOCUMENT_PARTIES_WITH_PERMISSION = 'orders/RECEIVE_ORDER_DOCUMENT_PARTIES_WITH_PERMISSION',
  RECEIVE_ORDER_DOCUMENT_PERMISSION_CANDIDATES = 'orders/RECEIVE_ORDER_DOCUMENT_PERMISSION_CANDIDATES',
  RECEIVE_ORDER_DOCUMENT_CONTENT = 'order/RECEIVE_ORDER_DOCUMENT_CONTENT',
  UPDATE_ORDER_DOCUMENT = 'orders/UPDATE_ORDER_DOCUMENT',
  APPEND_ORDER_DOCUMENT = 'orders/APPEND_ORDER_DOCUMENT',

  // Packages types keys
  EP_PACKAGE_SET_LATEST = 'order/EP_PACKAGE_SET_LATEST',
  EP_PACKAGE_ERROR = 'order/EP_PACKAGE_ERROR',

  // Multisite type keys
  MULTISITE_PROJECT_SET_SITES = 'order/MULTISITE_PROJECT_SET_SITES',
  MULTISITE_PROJECT_SET_GEODATA = 'order/MULTISITE_PROJECT_SET_GEODATA',
  MULTISITE_PROJECT_SET_BREADCRUMB_INFO = 'order/MULTISITE_PROJECT_SET_BREADCRUMB_INFO',
  MULTISITE_ORDER_SET_SETTLEMENT_DOCUMENT_PROGRESS = 'order/MULTISITE_ORDER_SET_SETTLEMENT_DOCUMENT_PROGRESS',
  MULTISITE_ORDER_CLEAR_SETTLEMENT_DOCUMENT_PROGRESS = 'order/MULTISITE_ORDER_CLEAR_SETTLEMENT_DOCUMENT_PROGRESS',

  // Order UI keys
  UI_SET_ORDER_TEAM_PANEL_OVERLAY = 'orders/UI_SET_ORDER_TEAM_PANEL_OVERLAY',
  UI_SET_MUTLI_DOCS_ORDER_ID = 'orders/UI_SET_MUTLI_DOCS_ORDER_ID',
  UI_SET_MUTLI_DOCS_DOCUMENT_IDS = 'orders/UI_SET_MUTLI_DOCS_DOCUMENT_IDS',
  UI_SET_MUTLI_DOCS_PROMPT_VISIBILITY = 'orders/UI_SET_MUTLI_DOCS_PROMPT_VISIBILITY',
  UI_CLEAR_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID = 'orders/UI_CLEAR_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID',
  UI_SET_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID = 'orders/UI_SET_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID',
  UI_SET_DOC_PERMISSIONS_CONTACT_INPUT_IDS = 'orders/UI_SET_DOC_PERMISSIONS_CONTACT_INPUT_IDS',

  // Sales Prices
  RECEIVE_SALES_PRICE = 'order/RECEIVE_SALES_PRICE',
  RECEIVE_OWNER_LIABILITIES = 'order/RECEIVE_OWNER_LIABILITIES',

  // Order Status
  RECEIVE_ORDER_STATUS = 'order/RECEIVE_ORDER_STATUS',

  UI_STAGE_DOCUMENT_UPLOADS = 'orders/UI_STAGE_DOCUMENT_UPLOADS',
  UI_CLEAR_STAGED_DOCUMENT_UPLOADS = 'orders/UI_CLEAR_STAGED_DOCUMENT_UPLOADS',

  UI_CLEAR_ACTIVE_UPLOAD_DOCUMENTS = 'orders/UI_CLEAR_ACTIVE_UPLOAD_DOCUMENTS',
  UI_QUEUE_ORDER_DOCUMENT_UPLOAD = 'orders/UI_QUEUE_ORDER_DOCUMENT_UPLOAD',
  UI_UPDATE_DOC_UPLOAD_PROGRESS = 'orders/UI_UPDATE_DOC_UPLOAD_PROGRESS',
  UI_COMPLETE_ACTIVE_DOCUMENT_UPLOAD = 'orders/UI_COMPLETE_DOCUMENT_UPLOAD',
  UI_REMOVE_SUCCESSFUL_UPLOADS = 'order/UI_REMOVE_SUCCESSFUL_UPLOADS',

  UI_SET_REMOVING_DOCUMENT = 'orders/UI_SET_REMOVING_DOCUMENT',
  UPDATE_ORDER_TYPE_SUCCESS = 'orders/UPDATE_ORDER_TYPE_SUCCESS',
  UI_SET_MULTISITE_SELECTION = 'orders/UI_SET_MULTISITE_SELECTION',

  // Bulk updates status progress
  SET_BULK_UPDATES_STATUS_PROGRESS = 'order/SET_BULK_UPDATES_STATUS_PROGRESS',
  CLEAR_BULK_UPDATES_STATUS_PROGRESS = 'order/CLEAR_BULK_UPDATES_STATUS_PROGRESS',

  // Delete Loan status progress
  SET_DELETE_ORDER_LOAN_STATUS_PROGRESS = 'order/SET_DELETE_ORDER_LOAN_STATUS_PROGRESS',
  CLEAR_DELETE_ORDER_LOAN_STATUS_PROGRESS = 'order/CLEAR_DELETE_ORDER_LOAN_STATUS_PROGRESS',
}

export type OrderAction =
  | ISetAffiliatesData
  | IOrderAddPartyToPartiesWithPermissionsAction
  | IOrderBusinessPartiesReceiveResultsAction
  | IOrderChargesResultsAction
  | IOrderClearAction
  | IOrderClearErrorAction
  | IOrderDeleteDisbursement
  | IOrderDepositsError
  | IMilestoneReceiveDepositsError
  | IOrderDocumentContentAction
  | IOrderDocumentErrorAction
  | IOrderDocumentPermissionsErrorAction
  | IOrderDocumentRemoveFromCacheAction
  | IOrderDocumentsAction
  | IOrderDocumentUpdateAction
  | IOrderDocumentAppendAction
  | IOrderErrorAction
  | IOrderEscrowProductionOfficesReceiveResultsAction
  | IOrderGetLatestEpPackageSetLatestAction
  | IOrderOtherPartiesReceiveResultsAction
  | IOrderPartiesReceiveResultsAction
  | IOrderProductsReceiveResultsAction
  | IOrderOwningOfficesReceiveResultAction
  | IOrderReceiveDeposits
  | IMilestoneReceiveDeposits
  | IOrderReceiveDisbursementInfo
  | IOrderReceiveDisbursements
  | IOrderReplaceDisbursementId
  | IOrderReceiveHoldFunds
  | IOrderReceiveOverdraftCauses
  | IOrderReceiveOverdraftRecoverableParties
  | IOrderReceiveOverdraftRecoverableTypes
  | IOrderReceiveOverdrafts
  | IOrderReceiveResultsAction
  | IOrderRecieveDocumentPermissionCandidatesAction
  | IOrderRecieveDocumentPermissionGranteesAction
  | IOrderRemovePartyContact
  | IOrderRemovePartyEmployee
  | IOrderRevokeAccess
  | IOrderGrantAccess
  | IOrderRequestAction
  | IOrderSetDocOrganizationContactsAction
  | IOrderSetDocOrganizationsAction
  | IOrderSetLatestEpPackageErrorAction
  | IOrderSetOrderOrganizationContacts
  | IOrderSetOrderOrganizations
  | IOrderSuccessAction
  | IOrderTitleProductionOfficesReceiveResultsAction
  | IReceiveOrderStatus
  | IReceiveOwnerLiabilities
  | IReceiveSalesPrices
  | ISetOrderAddresses
  | ISetOrderPropertiesAddresses
  | IUpdateCloseDateSuccess
  | IUpdateLoanInformationSuccess
  | IUpdateNameSuccess
  | IUpdateOrderTypeSuccess
  | IUpdateProductsSuccess
  | IUpdateServicesSuccess
  | IOrderSetFilterCardStatus
  | IOrdersetOfficeViewSearchValue
  // Multisite Project actions
  | IMultisiteProjectSetSitesAction
  | IMultisiteProjectSetGeodataAction
  | IMultisiteProjectBreadcrumbLinkInfoAction;

export type OrderUIAction =
  | IOrderSetMultiDocsDocumentIdsAction
  | IOrderSetMultiDocsDownloadPromptVisibilityAction
  | IOrderSetMultiDocsOrderIdAction
  | IOrderSetOrderTeamPanelOverlayAction
  | IOrderSetDocPermissionsContactInputIdsAction
  | IOrderSetSingleDocDownloadDocumentIdAction
  | IOrderClearSingleDocDownloadDocumentIdAction
  | IOrderStageDocumentsAction
  | IOrderClearStagedDocumentsAction
  | IOrderClearActiveUploadsAction
  | IOrderBeginDocumentUploadAction
  | IOrderUpdateUploadProgressAction
  | IOrderCompleteActiveUploadAction
  | IOrderRemoveSuccessfulUploads
  | IOrderDocumentSetRemovingAction
  | IOrderSetMultisiteSelectionAction
  | IOrderReceiptProgress
  | IOrderClearReceiptProgress
  | IIssueCheckProgress
  | IClearIssueCheckProgress
  | ISettlementDocumentProgress
  | IClearSettlementDocumentProgress
  | IMultisiteSettlementDocumentProgress
  | IClearMultisiteSettlementDocumentProgress
  | ISetBulkUpdatesStatusProgress
  | ISetDeleteOrderLoanProgress;

export interface IOrderReceiveDeposits {
  type: OrderTypeKeys.REQUEST_ORDER_DEPOSITS_SUCCESS;
  deposits: IOrderDeposit[];
}

export interface IOrderDepositsError {
  type: OrderTypeKeys.REQUEST_ORDER_DEPOSITS_ERROR;
  error: AxiosError;
}

export interface IMilestoneReceiveDeposits {
  type: OrderTypeKeys.REQUEST_ORDER_MILESTONE_DEPOSITS_SUCCESS;
  milestoneDeposits: IMilestoneDeposit[];
}

export interface IMilestoneReceiveDepositsError {
  type: OrderTypeKeys.REQUEST_ORDER_MILESTONE_DEPOSITS_ERROR;
  error: AxiosError;
}

export interface IOrderReceiptProgress {
  type: OrderTypeKeys.SET_ORDER_DEPOSIT_RECEIPT_PROGRESS;
  receiptsMetaData: IDepositReceiptProgressMetaData[];
}

export interface IIssueCheckProgress {
  type: OrderTypeKeys.SET_ISSUE_CHECK_DOCUMENT_PROGRESS;
  issueCheckMetaData: IIssueCheckDocumentProgressMetaData[];
}

export interface IClearIssueCheckProgress {
  type: OrderTypeKeys.CLEAR_ISSUE_CHECK_DOCUMENT_PROGRESS;
  issueCheckMetaData: IIssueCheckDocumentProgressMetaData | null;
}

interface ISetAffiliatesData {
  type: OrderTypeKeys.SET_AFFILIATES_DATA;
  affiliatesData?: IAffiliate[];
}

export interface ISettlementDocumentProgress {
  type: OrderTypeKeys.SET_SETTLEMENT_DOCUMENT_PROGRESS;
  items: ISettlementDocumentItemProgress[];
}

export interface IMultisiteSettlementDocumentProgress {
  type: OrderTypeKeys.MULTISITE_ORDER_SET_SETTLEMENT_DOCUMENT_PROGRESS;
  items: IMultisiteDocumentItem[];
}

export interface IClearMultisiteSettlementDocumentProgress {
  type: OrderTypeKeys.MULTISITE_ORDER_CLEAR_SETTLEMENT_DOCUMENT_PROGRESS;
  item: IMultisiteDocumentItem | null;
}

export interface IClearSettlementDocumentProgress {
  type: OrderTypeKeys.CLEAR_SETTLEMENT_DOCUMENT_PROGRESS;
  item: ISettlementDocumentItemProgress | null;
}

export interface IOrderClearReceiptProgress {
  type: OrderTypeKeys.CLEAR_ORDER_DEPOSIT_RECEIPT_PROGRESS;
  receiptMetaData: IDepositReceiptProgressMetaData | null;
}

export interface IOrderChargesResultsAction {
  type: OrderTypeKeys.REQUEST_ORDER_CHARGES_SUCCESS;
  charges: IOrderCharge[];
}

export interface IOrderDeleteDisbursement {
  type: OrderTypeKeys.DELETE_ORDER_DISBURSEMENT_SUCCESS;
  disbursementMetaData: IDisbursementDeleteMetaData;
}

export interface IOrderReceiveDisbursements {
  type: OrderTypeKeys.REQUEST_ORDER_DISBURSEMENTS_SUCCESS;
  disbursements: IOrderDisbursement[];
}

export interface IOrderReplaceDisbursementId {
  type: OrderTypeKeys.REPLACE_ORDER_DISBURSEMENT_ID;
  oldId: number;
  newId: number;
}

export interface IOrderReceiveHoldFunds {
  type: OrderTypeKeys.REQUEST_ORDER_HOLD_FUNDS_SUCCESS;
  holdFunds: IOrderHoldFund[];
}

export interface IOrderReceiveDisbursementInfo {
  type: OrderTypeKeys.REQUEST_ORDER_DISBURSEMENT_INFO_SUCCESS;
  disbursementInfo: IOrderDisbursement;
}

export interface IOrderReceiveOverdrafts {
  type: OrderTypeKeys.REQUEST_ORDER_OVERDRAFTS_SUCCESS;
  overdrafts: IOrderOverdraft[];
}

export interface IOrderReceiveOverdraftRecoverableTypes {
  type: OrderTypeKeys.REQUEST_OVERDRAFTS_RECOVERABLE_TYPES_SUCCESS;
  overdraftRecoverableTypes: IOverdraftRecoverableType[];
}

export interface IOrderReceiveOverdraftCauses {
  type: OrderTypeKeys.REQUEST_OVERDRAFTS_CAUSES_SUCCESS;
  overdraftCauses: IOverdraftCause[];
}

export interface IOrderReceiveOverdraftRecoverableParties {
  type: OrderTypeKeys.REQUEST_OVERDRAFTS_RECOVERABLE_PARTIES_SUCCESS;
  overdraftRecoverableParties: IOverdraftRecoverableParty[];
}

export interface IOrderSetOrderOrganizations {
  type: OrderTypeKeys.SET_ORDER_ORGANIZATIONS;
  organizations: IOrderBusinessPartiesHash;
}

export interface IOrderSetOrderOrganizationContacts {
  type: OrderTypeKeys.SET_ORDER_ORGANIZATION_CONTACTS;
  contacts: IOrderPartyContactsHash;
}

export interface IOrderRemovePartyContact {
  type: OrderTypeKeys.REMOVE_ORDER_PARTY_CONTACT;
  contactId: number;
}

export interface IOrderRevokeAccess {
  type: OrderTypeKeys.REVOKE_ORDER_ACCESS;
  parties: IOrderBusinessParty[];
}

export interface IOrderGrantAccess {
  type: OrderTypeKeys.GRANT_ORDER_ACCESS;
  parties: IOrderBusinessParty[];
}

export interface IOrderSetFilterCardStatus {
  type: OrderTypeKeys.SET_FILTER_CARD_STATUS;
  status: string | null;
}

export interface IOrdersetOfficeViewSearchValue {
  type: OrderTypeKeys.SET_OFFICE_VIEW_SEARCH_VALUE;
  officeViewSearchValue: string | null;
}

export interface IOrderRemovePartyEmployee {
  type: OrderTypeKeys.REMOVE_ORDER_PARTY_EMPLOYEE;
  employeeId: number;
  roleId: number;
}

export interface IUpdateNameSuccess {
  type: OrderTypeKeys.UPDATE_ORDER_NAME_SUCCESS;
  name: string;
  fileId: string;
}

export interface IUpdateCloseDateSuccess {
  type: OrderTypeKeys.UPDATE_ORDER_CLOSE_DATE_SUCCESS;
  closeDate: string;
  fileId: string;
  isCloseDateEstimated: boolean;
}

export interface IUpdateLoanInformationSuccess {
  type: OrderTypeKeys.UPDATE_ORDER_LOAN_AND_LIABILITY;
  fileId: string;
  updatedLoanAmounts: ILoanAmount[];
}

export interface IUpdateProductsSuccess {
  type: OrderTypeKeys.UPDATE_ORDER_PRODUCTS_SUCCESS;
  fileId: string;
  products: IOrderProduct[];
}

export interface IUpdateOrderTypeSuccess {
  type: OrderTypeKeys.UPDATE_ORDER_TYPE_SUCCESS;
  fileId: string;
  transactionType: TransactionType;
}

export interface IUpdateServicesSuccess {
  type: OrderTypeKeys.UPDATE_ORDER_SERVICES_COMPLETE;
  fileId: string;
  services: IService[];
  offices?: IProductionOffice[];
}

export interface ILoanAmountsHashTable {
  [id: string]: ILoanAmount;
}

export interface ILoanUpdatesHashTable {
  [id: string]: {
    amount: string;
    liabilityAmount: string;
  };
}

export interface ISetOrderPropertiesAddresses {
  type: OrderTypeKeys.SET_ORDER_PROPERTIES_ADDRESSES;
  payload: {
    addresses: IOrderPropertyFullAddress[];
  };
}

export interface ISetOrderAddresses {
  type: OrderTypeKeys.SET_ORDER_ADDRESSES;
  payload: {
    addresses: IAddress[];
  };
}

export interface IOrderSetMultiDocsOrderIdAction {
  type: OrderTypeKeys.UI_SET_MUTLI_DOCS_ORDER_ID;
  orderId: number;
}

export interface IOrderSetMultiDocsDocumentIdsAction {
  type: OrderTypeKeys.UI_SET_MUTLI_DOCS_DOCUMENT_IDS;
  documentIds: number[];
  documentArchiveFilesize: number;
}

export interface IOrderStageDocumentsAction {
  type: OrderTypeKeys.UI_STAGE_DOCUMENT_UPLOADS;
  documentsToStage: IStagedDocumentUpload[];
}

export interface IOrderClearStagedDocumentsAction {
  type: OrderTypeKeys.UI_CLEAR_STAGED_DOCUMENT_UPLOADS;
}

export interface IOrderClearActiveUploadsAction {
  type: OrderTypeKeys.UI_CLEAR_ACTIVE_UPLOAD_DOCUMENTS;
}

export interface IOrderBeginDocumentUploadAction {
  type: OrderTypeKeys.UI_QUEUE_ORDER_DOCUMENT_UPLOAD;
  stagedDocuments: IStagedDocumentUpload[];
}

export interface IOrderUpdateUploadProgressAction {
  type: OrderTypeKeys.UI_UPDATE_DOC_UPLOAD_PROGRESS;
  progress: number;
  fileName: string;
}

export interface IOrderCompleteActiveUploadAction {
  type: OrderTypeKeys.UI_COMPLETE_ACTIVE_DOCUMENT_UPLOAD;
  error?: Error;
  fileName: string;
}

export interface IOrderRemoveSuccessfulUploads {
  type: OrderTypeKeys.UI_REMOVE_SUCCESSFUL_UPLOADS;
}

export interface IOrderSetMultiDocsDownloadPromptVisibilityAction {
  type: OrderTypeKeys.UI_SET_MUTLI_DOCS_PROMPT_VISIBILITY;
  show: boolean;
}

export interface IOrderSetSingleDocDownloadDocumentIdAction {
  type: OrderTypeKeys.UI_SET_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID;
  documentId: DocumentId;
  fileSize: number;
}

export interface IOrderClearSingleDocDownloadDocumentIdAction {
  type: OrderTypeKeys.UI_CLEAR_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID;
  documentId: DocumentId;
  fileSize: number;
}

export interface IOrderSetOrderTeamPanelOverlayAction {
  type: OrderTypeKeys.UI_SET_ORDER_TEAM_PANEL_OVERLAY;
  enabled: boolean;
}

export interface IOrderSetDocPermissionsContactInputIdsAction {
  type: OrderTypeKeys.UI_SET_DOC_PERMISSIONS_CONTACT_INPUT_IDS;
  ids: string[];
}

export interface IOrderSetMultisiteSelectionAction {
  type: OrderTypeKeys.UI_SET_MULTISITE_SELECTION;
  siteFileIds: number[];
}

export interface IOrderDocumentPermissionsErrorAction {
  type: OrderTypeKeys.DOCUMENT_PERMISSIONS_ERROR;
  error: AxiosError | null;
}

export interface IOrderRecieveDocumentPermissionGranteesAction {
  type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_PARTIES_WITH_PERMISSION;
  parties: IOrderBusinessParty[];
}

export interface IOrderRecieveDocumentPermissionCandidatesAction {
  type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_PERMISSION_CANDIDATES;
  parties: IOrderBusinessParty[];
}

export interface IOrderDocumentErrorAction {
  type: OrderTypeKeys.DOCUMENT_ERROR;
}

export interface IOrderSetDocOrganizationsAction {
  type: OrderTypeKeys.SET_DOC_ORGANIZATIONS;
  organizations: IOrderBusinessPartiesHash;
}

export interface IOrderSetDocOrganizationContactsAction {
  type: OrderTypeKeys.SET_DOC_ORGANIZATION_CONTACTS;
  contacts: IOrderBusinessPartiesHash;
}

export interface IOrderAddDocPartyToOrderAction {
  type: OrderTypeKeys.ADD_DOC_PARTY_TO_ORDER;
  party: IOrderBusinessParty;
}

export interface IOrderBusinessPartiesHash {
  [key: string]: IOrderBusinessParty;
}

export interface IOrderRequestAction {
  type: OrderTypeKeys.REQUEST;
  requestType: OrderRequestTypes;
}

export interface IOrderSuccessAction {
  type: OrderTypeKeys.SUCCESS;
}

export interface IOrderErrorAction {
  type: OrderTypeKeys.ERROR;
  error: AxiosError | null;
}

export interface IOrderClearAction {
  type: OrderTypeKeys.CLEAR;
}

export interface IOrderClearErrorAction {
  type: OrderTypeKeys.CLEAR_ERROR;
}

export interface IOrderReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_RESULTS;
  order: IOrder | null;
  updateOrderDataOnly?: boolean;
}

export interface IOrderProductsReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_PRODUCTS_RESULTS;
  products: IOrderProduct[];
}

export interface IOrderDocumentsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENTS;
  documents: IDocument[];
  isDMSEnabled: boolean;
}

export interface IOrderDocumentUpdateAction {
  type: OrderTypeKeys.UPDATE_ORDER_DOCUMENT;
  document: IDocument;
  isTemplateInstance: boolean;
}

export interface IOrderDocumentAppendAction {
  type: OrderTypeKeys.APPEND_ORDER_DOCUMENT;
  document: IDocument;
  appendDoc: IDocument;
}

export interface IOrderDocumentRemoveFromCacheAction {
  type: OrderTypeKeys.REMOVE_CACHED_ORDER_DOCUMENT;
  removedDocumentId: number | string;
}

export interface IOrderDocumentSetRemovingAction {
  type: OrderTypeKeys.UI_SET_REMOVING_DOCUMENT;
  removingDocumentId: number | string;
}

export interface IOrderDocumentContentAction {
  type: OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_CONTENT;
  documentId: DocumentId;
  file: Blob;
}

export interface IOrderDocumentUser {
  email: string | null;
  fullName: string | null;
}

export enum IOrderDocumentSubType {
  EAGLE_PRO_PACKAGE = 'EagleProPackage',
  FAST = 'Fast',
  FAST_IMAGE = 'FastImage',
  DMS_INSTANCE = 'DmsInstance',
}

export interface IOrderProduct {
  id: number;
  fastId: number;
  name: string;
}

export interface IOrderBroker {
  fastRoleId: RoleType;
  fileBusinessPartyId;
  number;
  fileId: number;
  id: number;
  organizationId: number;
  roleId: number;
  roleName: RoleDescription;
}

export interface IOrderBusinessPartiesReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_BUSINESS_PARTIES_RESULTS;
  parties: IOrderBusinessParty[];
}

export interface IOrderOwningOfficesReceiveResultAction {
  type: OrderTypeKeys.RECEIVE_ORDER_OWNING_OFFICES_RESULTS;
  owningOffices: Array<IOwningOffice | IProductionOffice>;
}

export interface IOrderTitleProductionOfficesReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_TITLE_PRODUCTION_OFFICES_RESULTS;
  titleProductionOffices: IProductionOffice[];
}

export interface IOrderEscrowProductionOfficesReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_ESCROW_PRODUCTION_OFFICES_RESULTS;
  escrowProductionOffices: IProductionOffice[];
}

export interface IOrderPartiesReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_PARTIES_RESULTS;
  orderParties: IOrderBusinessParty[];
}
export interface IOrderOtherPartiesReceiveResultsAction {
  type: OrderTypeKeys.RECEIVE_ORDER_OTHER_PARTIES_RESULTS;
  otherParties: IOrderBusinessParty[];
}

export interface IOrderAddPartyToPartiesWithPermissionsAction {
  type: OrderTypeKeys.ADD_DOC_PARTY_TO_PARTIES_WITH_PERMISSIONS;
  party: IOrderBusinessParty;
}

export interface IOrderSetLatestEpPackageErrorAction {
  type: OrderTypeKeys.EP_PACKAGE_ERROR;
  error: AxiosError | null;
}

export interface IOrderGetLatestEpPackageSetLatestAction {
  type: OrderTypeKeys.EP_PACKAGE_SET_LATEST;
  package: IEagleProPackage | null;
}

export interface IMultisiteProjectSetSitesAction {
  type: OrderTypeKeys.MULTISITE_PROJECT_SET_SITES;
  payload: {
    sites: any;
    pagination: IPagination;
    locations: ILocationStateFilter;
  };
}

export interface IMultisiteProjectSetGeodataAction {
  type: OrderTypeKeys.MULTISITE_PROJECT_SET_GEODATA;
  payload: {
    geodata: IOrderSiteGeodata[];
    unmappableCount: number;
  };
}

export interface IMultisiteProjectBreadcrumbLinkInfoAction {
  type: OrderTypeKeys.MULTISITE_PROJECT_SET_BREADCRUMB_INFO;
  payload: {
    multisiteSiteId: number;
    linkInfo: ProjectLinkInfo;
  };
}

export interface IReceiveSalesPrices {
  type: OrderTypeKeys.RECEIVE_SALES_PRICE;

  payload: {
    salesPrices: ISalePrice[];
    totalSalesPrice?: null | number;
  };
}

export interface IReceiveOwnerLiabilities {
  type: OrderTypeKeys.RECEIVE_OWNER_LIABILITIES;
  payload: {
    ownerLiabilities: IOwnerLiability[];
  };
}

export interface IReceiveOrderStatus {
  type: OrderTypeKeys.RECEIVE_ORDER_STATUS;
  payload: {
    status: SiteStatus;
  };
}

export interface IOrderBusinessParty {
  borrowerTransactionFastId?: number;
  businessPartyName: string;
  businessPartyRoleFastId?: number;
  businessPartyRoleId: number;
  city: string;
  email: string;
  employeeId?: number;
  fileId?: number;
  id: number;
  is1099SRecordExist?: boolean;
  isFileAccessExpired?: boolean;
  isUploader?: boolean;
  key?: number;
  name?: string;
  organizationId?: number;
  partyId: number;
  phoneNumber: string;
  relatedBusinessPartyId?: number | null;
  role: string | null;
  sequenceNumber: number;
  sourceType?: OrderBusinessPartySourceType;
  state: string | null;
  transactionParty?: ITransactionParty;
  user?: IUser | null;
  userId?: number;
  value?: number;
  sdnInfo?: {
    status: SDNStatus;
    referenceNumber: number | null;
    hitId: number | null;
  };
  zip: string;
}

export interface IOrderPartyContact {
  businessPartyName: string;
  cellular: string;
  city?: string;
  email: string;
  id: string;
  phoneNumber: string;
  state?: string;
  roleDescription?: string;
}

export interface IOrderPartyContactsHash {
  [key: string]: IOrderPartyContact;
}

export enum OrderBusinessPartySourceType {
  CLARITY_FIRST = 'ClarityFirst',
  GENERATED = 'Generated',
}

export enum OwningOfficeRole {
  ACCOUNT_MANAGER = 'Account Manager',
  TITLE_OFFICER = 'Title Officer',
  TITLE_ASSISTANT = 'Title Assistant',
  ESCROW_OFFICER = 'Escrow Officer',
  ESCROW_ASSISTANT = 'Escrow Assistant',
  UNDERWRITER = 'Underwriter',
}

export enum LenderRole {
  ASSUMPTION_LOAN = 'Assumption Loan',
  NEW_LOAN = 'New Loan',
  NEW_LOAN_ATTORNEY_CO_COUNSEL = 'New Loan - Attorney: Co-Counsel',
  NEW_LOAN_ATTORNEY_LOCAL = 'New Loan - Attorney: Local',
  NEW_LOAN_ATTORNEY_PRIMARY = 'New Loan - Attorney: Primary',
  NEW_LOAN_LOAN_OFFICER = 'New Loan - Loan Officer',
  NEW_LOAN_MORTGAGE_BROKER = 'New Loan - Mortgage Broker',
  NEW_LOAN_PROCESSOR = 'New Loan - Processor',
  TRUSTEE = 'Trustee',
  PAYOFF_LOAN = 'Payoff Loan',
}

export enum OrderPartiesRole {
  ASSUMPTION_LOAN = 'Assumption Loan',
  BORROWER = 'Borrower',
  BORROWER_CONTACT = 'Borrower Contact',
  BORROWERS_ATTORNEY = "Borrower's Attorney",
  BORROWERS_ATTORNEY_CONTACT = "Borrower's Attorney Contact",
  BORROWERS_BROKER = "Borrower's Broker",
  BORROWERS_BROKER_CONTACT = "Borrower's Broker Contact",
  BORROWERS_EXCHANGE_COMPANY = "Borrower's Exchange Company",
  BORROWERS_RE_BROKER_TRANSACTION_COORDINATOR = "Borrower's RE Broker Transaction Coordinator",
  BORROWERS_RE_BROKER_TRANSACTION_COORDINATOR_CONTACT = "Borrower's RE Broker Transaction Coordinator Contact",
  BORROWERS_REAL_ESTATE_AGENT = "Borrower's Real Estate Agent",
  BORROWERS_REAL_ESTATE_AGENT_CONTACT = "Borrower's Real Estate Agent Contact",
  BORROWERS_REAL_ESTATE_BROKER = "Borrower's Real Estate Broker",
  BUSINESS_ENTITY = 'Business Entity',
  BUSINESS_SOURCE = 'Business Source',
  BUSINESS_SOURCE_CONTACT = 'Business Source Contact',
  BUYER = 'Buyer',
  BUYER_CONTACT = 'Buyer Contact',
  BUYERS_ATTORNEY = "Buyer's Attorney",
  BUYERS_ATTORNEY_CONTACT = "Buyer's Attorney Contact",
  BUYERS_BROKER = "Buyer's Broker",
  BUYERS_BROKER_CONTACT = "Buyer's Broker Contact",
  BUYERS_EXCHANGE_COMPANY = "Buyer's Exchange Company",
  BUYERS_RE_BROKER_TRANSACTION_COORDINATOR = "Buyer's RE Broker Transaction Coordinator",
  BUYERS_RE_BROKER_TRANSACTION_COORDINATOR_CONTACT = "Buyer's RE Broker Transaction Coordinator Contact",
  BUYERS_REAL_ESTATE_AGENT = "Buyer's Real Estate Agent",
  BUYERS_REAL_ESTATE_AGENT_CONTACT = "Buyer's Real Estate Agent Contact",
  BUYERS_REAL_ESTATE_BROKER = "Buyer's Real Estate Broker",
  EXCHANGE_COMPANY = 'Exchange Company',
  EXCHANGE_COMPANY_CONTACT = 'Exchange Company Contact',
  BUYERS_EXCHANGE_COMPANY_CONTACT = "Buyer's Exchange Company Contact",
  SELLERS_EXCHANGE_COMPANY_CONTACT = "Seller's Exchange Company Contact",
  BORROWERS_EXCHANGE_COMPANY_CONTACT = "Borrower's Exchange Company Contact",
  INDIVIDUAL = 'Individual',
  LENDER = 'Lender',
  LENDER_POLICY = 'Lender Policy',
  LOAN = 'Loan',
  MISCELLANEOUS = 'Miscellaneous',
  NEW_LOAN_ATTORNEY_CO_COUNSEL = 'New Loan - Attorney: Co-Counsel',
  NEW_LOAN_ATTORNEY_LOCAL = 'New Loan - Attorney: Local',
  NEW_LOAN_ATTORNEY_PRIMARY = 'New Loan - Attorney: Primary',
  NEW_LOAN_LENDER = 'New Loan',
  NEW_LOAN_LOAN_OFFICER = 'New Loan - Loan Officer',
  NEW_LOAN_LOAN_PROCESSOR = 'New Loan - Processor',
  NEW_LOAN_MORTGAGE_BROKER = 'New Loan - Mortgage Broker',
  OUTSIDE_ESCROW_COMPANY = 'Outside Escrow Company',
  OUTSIDE_TITLE_COMPANY = 'Outside Title Company',
  OWNER_POLICY = 'Owner Policy',
  OWNERS_ASSOCIATION = 'Homeowner Association', // Text will be reverted back to Owners Association once Eclipses fixes issue
  PARTICIPANT = 'Participants',
  PAYOFF_LOAN = 'Payoff Loan',
  SEARCH_VENDOR = 'Search Vendor',
  SELLER = 'Seller',
  SELLER_CONTACT = 'Seller Contact',
  SELLERS_ATTORNEY = "Seller's Attorney",
  SELLERS_ATTORNEY_CONTACT = "Seller's Attorney Contact",
  SELLERS_BROKER = "Seller's Broker",
  SELLERS_BROKER_CONTACT = "Seller's Broker Contact",
  SELLERS_EXCHANGE_COMPANY = "Seller's Exchange Company",
  SELLERS_RE_BROKER_TRANSACTION_COORDINATOR = "Seller's RE Broker Transaction Coordinator",
  SELLERS_RE_BROKER_TRANSACTION_COORDINATOR_CONTACT = "Seller's RE Broker Transaction Coordinator Contact",
  SELLERS_REAL_ESTATE_AGENT = "Seller's Real Estate Agent",
  SELLERS_REAL_ESTATE_AGENT_CONTACT = "Seller's Real Estate Agent Contact",
  SELLERS_REAL_ESTATE_BROKER = "Seller's Real Estate Broker",
  SPOUSES = 'Spouses',
  SURVEY = 'Survey',
  TAX_COLLECTOR = 'Tax Collector',
  TRANSACTION_COORDINATOR = 'Transaction Coordinator',
  TRUST_ESTATE = 'Trust/Estate',
  TRUSTEE = 'Trustee',
  UTILITY = 'Utility',
}

export enum OrderPartyRoleId {
  ASSUMPTION_LOAN = 673,
  BORROWERS_ATTORNEY = 322,
  BORROWERS_RE_BROKER_TRANSACTION_COORDINATOR = 2368,
  BORROWERS_REAL_ESTATE_AGENT = 685,
  BORROWERS_REAL_ESTATE_BROKER = 323,
  BUSINESS_ENTITY = 51,
  BUSINESS_SOURCE = 690,
  BUYER = 113,
  BUYERS_ATTORNEY = 322,
  BUYERS_RE_BROKER_TRANSACTION_COORDINATOR = 2368,
  BUYERS_REAL_ESTATE_AGENT = 685,
  BUYERS_REAL_ESTATE_BROKER = 323,
  EXCHANGE_COMPANY = 1501,
  INDIVIDUAL = 48,
  MISCELLANEOUS = 679,
  NEW_LOAN_ATTORNEY_CO_COUNSEL = 703,
  NEW_LOAN_ATTORNEY_LOCAL = 704,
  NEW_LOAN_ATTORNEY_PRIMARY = 702,
  NEW_LOAN_LENDER = 688,
  NEW_LOAN_LOAN_OFFICER = 3220,
  NEW_LOAN_LOAN_PROCESSOR = 3221,
  NEW_LOAN_MORTGAGE_BROKER = 670,
  NONE = 0,
  OUTSIDE_ESCROW_COMPANY = 90,
  OUTSIDE_TITLE_COMPANY = 317,
  OWNERS_ASSOCIATION = 93,
  PAYOFF_LOAN = 674,
  SEARCH_VENDOR = 1680,
  SELLER = 114,
  SELLERS_ATTORNEY = 325,
  SELLERS_RE_BROKER_TRANSACTION_COORDINATOR = 2371,
  SELLERS_REAL_ESTATE_AGENT = 686,
  SELLERS_REAL_ESTATE_BROKER = 326,
  SPOUSES = 49,
  SURVEY = 97,
  TAX_COLLECTOR = 99,
  TRUST_ESTATE = 50,
  TRUSTEE = 92,
  UTILITY = 95,
}

export enum DocumentWatermarks {
  NONE = 'None',
  PROFORMA = 'Proforma',
  DRAFT = 'Draft',
}

type DocumentWatermark =
  | DocumentWatermarks
  | FA_NCS_ClarityFirst_DocumentInstance_Models_WatermarkType;

export interface IDocumentWatermarkEntry {
  key: DocumentWatermarks;
  type: FA_NCS_ClarityFirst_DocumentInstance_Models_WatermarkType;
  label: string;
}

export interface IDocument {
  createdByUser: IOrderDocumentUser;
  documentType: string | null;
  documentTypeId: number | null;
  extension: string;
  fileSize: number;
  id: number;
  isNew: boolean;
  modifiedDate: string;
  name: string;
  displayName?: string;
  /**
   * Set by the backend only.
   * To secure a document, add `SEC-` prefix to the `name` property.
   */
  readonly secured: boolean;
  sharedWith: IOrderDocumentUser[];
  subType: string;
  documentContent: Blob | File;
  notes: string | null;
  wireInstructionFileIds: number[];
  fileId: number;
  creationDate: string;
  additionalInfo: string;
  customerDocumentId: number;
  templateInstanceId?: string;
  isDepositDocument?: boolean;
  is1099SDocument?: boolean;
  watermark?: DocumentWatermark;
  documentStatus: DocumentStatuses;
  pageCount: number;
  eagleProFlag: boolean;
  associatedDocumentId: string;
  effectiveDate?: string; // Date string
  taskDocumentId?: number;
}

interface IOrderOffice {
  id: number;
  businessUnitId: number; // represents fastId
  officeId: number;
  fileId: number;
  address: IAddress;
  name: string;
  sequenceNumber: number;
  phoneNumber: string;
  email: string;
  city: string;
  state: string;
  street?: string;
  zip?: string;
  addresses?: IAddress[];
}

export interface IProductionOfficeEmployee {
  id: number;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
}
export interface IProductionOffice extends IOrderOffice {
  type: ProductionOfficeType;
  assistant?: IProductionOfficeEmployee;
  officer?: IProductionOfficeEmployee;
  regionId?: number;
  regionName?: string;
}

export interface IOwningOffice extends IOrderOffice {
  type: OwningOfficeType;
}

export enum ProductionOfficeType {
  TITLE = 'title',
  ESCROW = 'escrow',
}

export enum OrderServices {
  TITLE = 'Title',
  ESCROW = 'Escrow',
}

export enum OwningOfficeType {
  TITLE = 'Title Owning',
  ESCROW = 'Escrow Owning',
}

export enum TotalCountRowDescription {
  SELLER_PARTY = 'Seller Party',
  SELLER_PARTIES = 'Seller Parties',
  BUYER_PARTY = 'Buyer Party',
  BUYER_PARTIES = 'Buyer Parties',
  BORROWER_PARTY = 'Borrower Party',
  BORROWER_PARTIES = 'Borrower Parties',
}

export enum ProductionOffices {
  TITLE_PRODUCTION_OFFICE = 'Title Production Office',
  ESCROW_PRODUCTION_OFFICE = 'Escrow Production Office',
}

export enum OrderPartyGroup {
  PRINCIPALS = 'principals',
  LENDERS = 'lenders',
  OTHER = 'other',
}

export enum OrderLoadingKeys {
  orderAddContactLoading = 'orderAddContactLoading',
  orderDepositsLoading = 'orderDepositsLoading',
  orderDisbursementsLoading = 'orderDisbursementsLoading',
  orderDisbursementInfoLoading = 'orderDisbursementInfoLoading',
  orderDocumentPartiesPermissionsLoading = 'orderDocumentPartiesPermissionsLoading',
  orderDocumentPermissionsLoading = 'orderDocumentPermissionsLoading',
  orderDocumentsLoading = 'orderDocumentsLoading',
  orderFileDetailsLoading = 'orderFileDetailsLoading',
  orderLatestEpPackageLoading = 'orderLatestEpPackageLoading',
  orderOrganizationContactsLoading = 'orderOrganizationContactsLoading',
  orderOrganizationsLoading = 'orderOrganizationsLoading',
  orderProductsLoading = 'orderProductsLoading',
  orderProjectGeodataLoading = 'orderProjectGeodataLoading',
  orderProjectSitesLoading = 'orderProjectSitesLoading',
  orderTeamPanelBusinessPartiesLoading = 'orderTeamPanelBusinessPartiesLoading',
  orderTeamPanelEscrowOfficesLoading = 'orderTeamPanelEscrowOfficesLoading',
  orderTeamPanelLoading = 'orderTeamPanelLoading',
  orderTeamPanelOrderPartiesLoading = 'orderTeamPanelOrderPartiesLoading',
  orderTeamPanelOtherPartiesLoading = 'orderTeamPanelOtherPartiesLoading',
  orderTeamPanelTitleOfficesLoading = 'orderTeamPanelTitleOfficesLoading',
  editOrderPartySavingInProgress = 'editOrderPartySavingInProgress',
  editOrderPartyUpdatingInProgress = 'editOrderPartyUpdatingInProgress',
  orderTeamPanelOwningOfficesLoading = 'orderTeamPanelOwningOfficesLoading',
}

export enum OrderModalKeys {
  orderAddContactModal = 'orderAddContactModal',
  assignBusinessPartiesModal = 'assignBusinessPartiesModal',
}

export interface IEagleProPackage {
  createdByUser: {
    email: string;
    fullName: string;
  };
  creationDate: string | null;
  modifiedDate: string | null;
  fileId: number;
  name: string;
  packageId: number;
  packageType: string;
  isSharedExternally: boolean;
  viewLink: string;
}

export const isEagleProPackage = (pkg: any): pkg is IEagleProPackage => {
  return pkg?.packageId !== undefined;
};

export interface IFilePartyContactForm {
  organizationBusinessPartyRoleId: number;
  city?: string;
  email: string;
  name?: string;
  stateCode?: string;
  partyId: number;
  mfaPhoneNumber?: string;
}

export type SitesSortField = 'closedate' | 'number' | 'status';
export type SortDirection = 'ascending' | 'descending';

export interface ISitesQuery {
  filters: ISitesFilters;
  page: number;
  searchTerm: string;
  sortedByDirection: SortDirection;
  sortedByField: SitesSortField;
}

export interface ISitesFilters {
  borrowers?: string[];
  buyers?: string[];
  closingDateFrom?: [string];
  closingDateTo?: [string];
  groups?: string[];
  sellers?: string[];
  states?: string[];
  counties?: string[];
  cities?: string[];
  statuses?: string[];
  lenders?: string[];
}
export type ISitesFilterKeys = keyof ISitesFilters;

export interface ILocationStateFilter {
  [statesCode: string]: number;
}

export interface ILocationFilterAggregation {
  [country: string]: ILocationStateFilter;
}

export interface IOrderClosingDateFields {
  estimatedClosingRawValue: Date | null;
  estimatedClosingValue: string | null;
  estimatedDateIsActive: boolean;
  updatePolicies: {
    [key in 'disbursementDate' | 'prorateDate']: boolean;
  };
}

export interface IOrderEstimatedClosingDateFields {
  estimatedClosingDateUpdatePolicies: {
    [key in 'disbursementDate' | 'prorateDate']: boolean;
  };
}

export type Service = 'escrow' | 'sub escrow' | 'title';

export enum LoanKindTypes {
  PAYOFF = 0,
  NEW = 1,
  ASSUMPTION = 2,
}

export interface IOrderLoanResult {
  id: number;
  organization: string | null;
  organizationId?: string | number | null;
  contact: string | null;
  loanKind: LoanKindTypes;
  priority: number;
  amount: number | null;
  loanLiabilityAmount?: number | null;
  lenderId?: number;
}

export enum EclipseLoanTypes {
  PAYOFF = 0,
  NEW = 970,
  ASSUMPTION = 761,
  LINEOFCREDIT = 979,
  CONSTRUCTIONMORTGAGE = 974,
}

export enum AssumptionLoanPaymentFrequency {
  DAY = 409,
  MONTH = 411,
  QUARTER = 412,
  SEMI_ATUAL = 414,
  TRIMESTER = 413,
  WEEK = 410,
  YEAR = 415,
}

export enum AssumptionLoanPaymentTypes {
  INTEREST_ONLY = 450,
  PRINCIPAL_INTEREST = 451,
  PRINCIPAL_INTEREST_TAX_INSURANCE = 452,
}

interface IAssumptionLoanInfo {
  nextPaymentDueDate?: string | null;
  interestPaidToDate?: string | null;
  lenderPolicyLiabilityAmount?: number | null;
  noteDate?: string | null;
  originalAmount?: number | null;
  paymentAmount?: number | null;
  paymentType?: AssumptionLoanPaymentTypes;
  paymentFrequency?: AssumptionLoanPaymentFrequency;
  maturityDate?: string | null;
  unpaidPrincipalBalance?: number | null;
}

interface ILoanMortgageBroker {
  organizationId?: number;
  contactId?: number | null;
  isAdhocContact: boolean;
  brokerFee?: number;
  paymentMethodId?: number;
}

interface INewLoanInfo {
  vendorNumber?: string | null;
  fundAmount?: number | null;
  loanAmount?: number | null;
  loanLiabilityAmount?: number | null;
  mortgageInsuranceCaseNumber?: string | null;
  fundingDate?: string | null;
  rescissionBeginDate?: string | null;
  rescissionEndDate?: string | null;
  rescissionDays?: number | null;
  signingDate?: string | null;
  fundsReceivedFlag?: boolean;
  nmlsid?: string | null;
  contactNMLSID?: string | null;
  mortgageBroker?: ILoanMortgageBroker;
  relatedParties?: ILoanRelatedParty[];
}

interface ILoanParties {
  trustorMortgagor?: string;
  beneficiaryMortgagee?: string;
  originalBeneficiaryMortgagee?: string;
  assigneeBeneficiaryMortgagee?: string;
  trusteeOrganizationId?: number;
}

interface ILoanRecording {
  instrumentNumber?: string | null;
  book?: string | null;
  page?: string | null;
  trustDeedDate?: string | null;
  recordingDate?: string | null;
  titleInsuranceMortgage?: string | null;
  proposedInsured?: string | null;
}

export interface IOrderLoan {
  id?: number;
  loanKind: LoanKindTypes;
  lenderOrganizationId?: number;
  lenderId?: number;
  eclipseLoanTypeId: EclipseLoanTypes;
  contactId?: number | null;
  isAdhocContact: boolean;
  loanNumber?: string | null;
  assumptionLoanInfo?: IAssumptionLoanInfo | null;
  newLoanInfo?: INewLoanInfo | null;
  referenceNote?: string | null;
  charges?: ILoanCharge[] | null;
  loanInterests?: ILoanInterestCharge[] | null;
  restrictLenderUpdates?: boolean;
  restrictDemandUpdates?: boolean | null;
  loanParties?: ILoanParties | null;
  recordingInfo?: ILoanRecording | null;
  siteFileId?: number | null;
  priority?: number | null;
  isClosingFunded?: boolean | null;
}

export enum LoanChargeCategoryTypeIds {
  PRINCIPAL_BALANCE = 379,
  INTEREST_CHARGE = 381,
  STANDARD_CHARGE = 380,
}

export interface ILoanCharge {
  id?: number;
  description?: string;
  categoryTypeId?: number;
  isDisbursed?: boolean | null;
  disbursementId?: number | null;

  buyerCharge?: number;
  buyerPaidBeforeClosing?: number;
  buyerPaidAtClosing?: number;
  buyerPaymentMethodId?: number;
  buyerCredit?: number;
  buyerCreditTypeId?: number;
  buyerPaidByOthersAmount?: number;
  buyerPaidByOthersTypeId?: number;

  sellerCharge?: number;
  sellerPaidBeforeClosing?: number;
  sellerPaidAtClosing?: number;
  sellerPaymentMethodId?: number;
  sellerCredit?: number;
  sellerCreditTypeId?: number;
  sellerPaidByOthersAmount?: number;
  sellerPaidByOthersTypeId?: number;
  hasAllocations?: boolean;
}

export interface ILoanInterestCharge extends ILoanCharge {
  perDiemAmount?: number | null;
  fromDate?: string | null;
  toDate?: string | null;
}

export enum ChargePaymentMethodIds {
  LENDER = 84,
  POC = 2390,
  AT_CLOSING = 2647,
  CHECK = 384,
  NO_CHECK = 3171,
  RBL = 2421,
  MORTGAGE_BROKER = 670,
}

// Mapped to: Workspace.Common.Models.Dto.Loans.RelatedPartyDto.
interface ILoanRelatedParty {
  id: number;
  organizationId: number;
  contactId?: number | null;
  isAdhocContact: boolean;
  relatedPartyTypeId: number;
  hasFinalInvoice?: boolean;
}

export interface ILoanDisburseCharges {
  payeeId: number | null;
  paymentMethod: number | null;
  charges: number[];
}

export interface IAffiliate {
  affiliateCompanyContact?: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  partyId?: number;
  id?: number;
  name: string;
}

export enum OrderStatusIds {
  PENDING = 1507,
  OPEN = 151,
  OPEN_IN_ERROR = 152,
  CANCELLED = 153,
  CLOSED = 154,
}

export interface IListClosingGroups {
  closingGroups: IClosingGroup[];
}

export interface IClosingGroup extends Pick<IOrder, 'projectFileId'> {
  id: number;
  name: string;
  status?: IOrder['status'];
  creationDate?: string;
  closingDate?: string;
  isEstimatedClosingDate: boolean;
  orders: IClosingGroupOrder[];
  label?: string;
}

export interface IClosingGroupOrder extends Omit<IPartialOrder, 'fileID'> {
  fileID: number;
}

export interface ISetBulkUpdatesStatusProgress
  extends Pick<IOrderUiState, 'bulkUpdatesStatusProgress'> {
  type: OrderTypeKeys.SET_BULK_UPDATES_STATUS_PROGRESS;
}

export interface ISetDeleteOrderLoanProgress
  extends Pick<IOrderUiState, 'deleteOrderLoanProgress'> {
  type: OrderTypeKeys.SET_DELETE_ORDER_LOAN_STATUS_PROGRESS;
}

export interface ISharedStatement {
  statementId: number;
  statementType: string;
  projectFileNumber: string;
  closingGroupId?: number;
  closingGroupName?: string;
  fileBusinessPartyId?: number;
  businessPartyName?: string;
  siteFileId?: number;
  siteFileNumber?: string;
  isSingleOrder?: boolean;
}

export interface IMilestoneDeposit {
  amount: number; // decimal
  depositType: string;
  id: number;
  status: DepositStatus;
  issueDate: Date;
  source: string;
  isConsideredEarnestMoney: boolean;
}
